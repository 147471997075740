import { BrandHeaderBase } from "../../../components/BrandHeaderBase";
import { Distributor, Theme } from "../../../model/theme.typer";
import { DNBLogo } from "../logo";

import "./dnb.scss";

const BrandHeader = () => <BrandHeaderBase logo={<DNBLogo />} />;

export const theme: Theme = {
    distributor: Distributor.DNB,
    BrandHeader
};
